<template>
  <div class="log">
    <div class="login">
      <div class="login-width">
        <div class="login-center-width">
          <div class="login-center-width-img">
            <Isvg icon-class="logo-text" class-name="item-logo"/>
          </div>
          <div class="login-center-width-welcome "><span>重置密码</span></div>
          <ValidationObserver ref="resetPasswordForm">
            <div class="content">
              <ValidationProvider name="新密码" rules="required" v-slot="{ errors }" mode="passive">
                <div class="content-Newpassword">
                  <label>新密码</label>
                  <div>
                    <ul>
                      <li>
                        <Isvg icon-class="eye-close" class-name="visible-password" @click.native="showPas" v-if="pas == 1"/>
                        <Isvg icon-class="eye-open" class-name="invisible-password" @click.native="blankPas" v-if="pas == 2"/>
                        <input
                            ref="showPas"
                            type="password"
                            v-model="password"
                            placeholder="请设置密码6-16位字符"
                            class="input-288 login-input-heightbot login-input-block"
                        />
                      </li>
                      <li>
                        <!-- 密码错误的提示文字 -->
                        <p class="p">{{ errors[0] }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </ValidationProvider>
            </div>

            <div class="content">
              <ValidationProvider name="新密码确认" rules="required" v-slot="{ errors }" mode="passive">
                <div class="content-Newpassword">
                  <label class="content-Newpassword2">新密码确认</label>
                  <div>
                    <ul>
                      <li>
                        <i class="visible-password" @click="NewshowPas" v-if="newPas == 1"></i>
                        <i class="invisible-password" @click="NewblankPas" v-if="newPas == 2"></i>
                        <input
                            type="password"
                            ref="showPass"
                            v-model="password_again"
                            placeholder="请输入新密码二次确认"
                            class="input-288 login-input-heightbot login-input-block"
                        />
                      </li>
                      <li>
                        <!-- 与新密码不一致的提示错误 -->
                        <p class="p">{{ errors[0] }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <div class="content-button">
              <el-button class="button-blue-400" :loading="resetLoding" @click="toReset()">重置</el-button>
            </div>
          </ValidationObserver>
        </div>
        <p class="register-goLogin">
          已有账号？
          <a href="javascript:void(0);" @click="$router.push('/login')">马上登录</a>
        </p>
      </div>
      <p class="register-beihao fs smallsize-font">© 2022 gogowork.cn All Rights Reserved 京IPC备：2021019219号-1</p>
    </div>
  </div>
</template>

<script>
import {requestFindPasswordTwo} from '@/api/index';

export default {
  name: 'reset',
  data() {
    return {
      password: '',
      password_again: '',
      /* 新密码 */
      pas: 1,
      /* 新密码确认 */
      newPas: 1,
      resetLoding: false,
    };
  },
  created() {
    const fptoken = localStorage.getItem('find_password_token');
    if (fptoken == null) {
      this.$router.push('/retrievePassword');
    }
  },
  mounted() {
    this.keyDown()
  },
  methods: {
    // 显示密码
    showPas() {
      this.pas = 2;
      this.$refs.showPas.type = 'text';
    },
    // 隐藏密码
    blankPas() {
      this.pas = 1;
      this.$refs.showPas.type = 'password';
    },
    // 显示密码
    NewshowPas() {
      this.newPas = 2;
      this.$refs.showPass.type = 'text';
    },
    // 隐藏密码
    NewblankPas() {
      this.newPas = 1;
      this.$refs.showPass.type = 'password';
    },
    toReset() {
      this.$refs.resetPasswordForm.validate().then(success => {
        if (!success) {
          return;
        } else {
          this.resetPassword();
        }
      });
    },
    async resetPassword() {
      let data = {
        new_password: this.password,
        new_password_again: this.password_again
      };
      const res = await requestFindPasswordTwo(data, localStorage.getItem('find_password_token'));
      if (res.statusCode == 200) {
        this.resetLoding = true
        this.$notify({
          message: '密码已重置,前往登录...',
          type: 'success'
        });
        localStorage.removeItem('find_password_token');
        this.$router.push('/login');
      } else {
        this.$notify({
          message: res.message,
          type: 'warning'
        });
      }
    },
    //按钮监听键盘
    keyDown() {
      let that = this
      //监听键盘按钮
      document.onkeydown = function (event) {
        let e = event || window.event;
        let keyCode = e.keyCode || e.which;
        switch (keyCode) {
          case 13://enter
            that.toReg()
            break;
          default:
            break;
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.item-logo {
  width: 8em !important;
  height: 2em !important;
  margin-top: 40px;
}
.login{
  padding-top: 60px;
}
.reset-center-width-password {
  font-size: 18px;
  text-align: center;
  margin: 20px 0 40px 0;
}

.content {
  width: 300px;
  margin-bottom: 14px;

  .content-Newpassword {
    display: flex;
    justify-content: space-between;

    label {
      line-height: 36px;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      width: 75px;
      text-align: right;
    }

    p {
      span {
        margin-left: 10px;
        font-size: 10px;
        font-weight: 400;
        color: #e4007f;
      }
    }
  }
}

.content-Newpassword2 {
  width: 70px;
}

span {
  display: block;
}

ul {
  & > li:first-of-type {
    position: relative;
    width: 100%;
    line-height: 36px;

    .capptalize {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 38px;
      top: 10px;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: 16px 16px;
      cursor: pointer;
    }

    .visible-password {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 1;
      cursor: pointer;
    }

    .invisible-password {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 1;
      cursor: pointer;
    }
  }
}

.p {
  font-size: 10px;
  color: #e4007f;
  margin-left: 10px;
}

.button-blue-400 {
  height: 36px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
